import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/testale/grandiaziende-noprod-deploy/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { useMutation, useQueryClient } from "react-query";
/**
 * Create Address Service for api createdAddress.
 * This method is used to create the address and generate an ID from ApicWeb services.
 * API "care/v1/address"
 * Method: POST
 * @param payload
 * @param customOptions
 * @returns
 */ export var createAddressService = function(payload, customOptions) {
    return nextClient.post(API.ADDRESS, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run createAddressService using the validateAddress service response as input.
 * Call this after validateAddress/offeringServiceability have been called.
 * @returns
 */ export var useCreateAddress = function(customOptions) {
    var queryClient = useQueryClient();
    var keyString = "createdAddress";
    return useMutation("createAddress", function(req) {
        return createAddressService(req.address, customOptions);
    }, {
        onMutate: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(data) {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            if ((data === null || data === void 0 ? void 0 : data.isBilling) === true) {
                                keyString = "billingCreatedAddress";
                            } else if ((data === null || data === void 0 ? void 0 : data.isDelivery) === true) {
                                keyString = "deliveryCreatedAddress";
                            } else if ((data === null || data === void 0 ? void 0 : data.isPickup) === true) {
                                keyString = "pickupCreatedAddress";
                            }
                        case 1:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(data) {
                return _ref.apply(this, arguments);
            };
        }(),
        onSuccess: function(data) {
            var ref;
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
        },
        onError: function(error) {
            var ref;
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
        }
    });
};
