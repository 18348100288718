import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/testale/grandiaziende-noprod-deploy/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { handleEnv } from "@vfit/shared/providers";
// const MOBILE_OFFER_LIST_EP = isDevLocal
//   ? `${API.CMS_GET_MOBILE_OFFER_LIST}`
//   : `${API.CMS_GET_MOBILE_OFFER_LIST}.json`;
// const MOBILE_OFFER_PRODUCTS_EP: string = isDevLocal
//   ? `${API.CMS_GET_MOBILE_LINE_PRODUCTS}`
//   : `${API.CMS_GET_MOBILE_LINE_PRODUCTS}.json`;
/**
 * @DEPRECATED
 * This component has been deprecated
 */ var getMobileLineProductsSlug = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var ref, ref1, allProductRes, allProductId, allMobileProductsRes, allMobileProducts, allSlugs;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    _ctx.next = 3;
                    return fetch("".concat(handleEnv("NX_CMS"), "/", "MOBILE_OFFER_LIST_EP"));
                case 3:
                    allProductRes = _ctx.sent;
                    _ctx.next = 6;
                    return allProductRes.json();
                case 6:
                    allProductId = _ctx.sent;
                    _ctx.next = 9;
                    return fetch("".concat(handleEnv("NX_CMS"), "/", "MOBILE_OFFER_PRODUCTS_EP"));
                case 9:
                    allMobileProductsRes = _ctx.sent;
                    _ctx.next = 12;
                    return allMobileProductsRes.json();
                case 12:
                    allMobileProducts = _ctx.sent;
                    allSlugs = [];
                    allProductId === null || allProductId === void 0 ? void 0 : (ref = allProductId.offerlist) === null || ref === void 0 ? void 0 : (ref1 = ref.offers) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(offerId) {
                        var ref, ref1, ref2, ref3, ref4;
                        if (offerId.id && (allMobileProducts === null || allMobileProducts === void 0 ? void 0 : (ref = allMobileProducts[offerId.id]) === null || ref === void 0 ? void 0 : (ref1 = ref.offerdetail) === null || ref1 === void 0 ? void 0 : ref1.slug)) {
                            if (!allSlugs.includes((ref2 = allMobileProducts[offerId.id].offerdetail) === null || ref2 === void 0 ? void 0 : ref2.slug)) allSlugs.push(allMobileProducts === null || allMobileProducts === void 0 ? void 0 : (ref3 = allMobileProducts[offerId.id]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.offerdetail) === null || ref4 === void 0 ? void 0 : ref4.slug);
                        }
                    });
                    if (allSlugs) {
                        _ctx.next = 17;
                        break;
                    }
                    return _ctx.abrupt("return", []);
                case 17:
                    return _ctx.abrupt("return", allSlugs.map(function(fileName) {
                        return {
                            params: {
                                slug: fileName.replace(/\.md$/, "")
                            }
                        };
                    }));
                case 18:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getMobileLineProductsSlug() {
        return _ref.apply(this, arguments);
    };
}();
export { getMobileLineProductsSlug };
